/** fa icon spinner **/
@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
    50% { -webkit-transform: rotate(180deg); transform:rotate(180deg); }
    100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }
}

.spinner{
    animation: spin 2s linear infinite;
}

/** end fa icon spinner **/
