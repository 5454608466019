.reportContainer {
    height: 99vh;
    opacity: 0;
}
.dashboardContainer {
    height: 99vh;
    opacity: 0;
}
#PBILoadingCircle {
    opacity: 1;
    pointer-events: none;
}
#loadingLogo {
    position: fixed;
    left: Calc(50vw - 96px);
    top: Calc(50vh - 96px);
}