.App {
    text-align: center;
}

.AppGrid {
    user-select: none;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Lato-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Raleway-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Font Awesome 5 Free Bold';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url("./fonts/fa-solid-900.ttf") format("truetype");
}

@font-face {
    font-family: 'Font Awesome 5 Free Regular';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("./fonts/fa-regular-400.ttf") format("truetype");
}

.fa {
    font-family: 'Font Awesome 5 Free Bold', serif;
    font-weight: 900;
}

