section {
    width: 100%;
    height: 10000px;
    text-align: center;
    padding: 50px 0;
    font-size: 10rem;
}

.pressed h6 {
    font-size: 2rem;
}

.pressed p {
    font-size: 1rem;
}

.pressed span {
    color: transparent;
    font-family: 'Oswald', sans-serif;
    position: relative;
    z-index: 0;
}

.pressed span:before, .pressed span:after {
    content: '404';
    position: absolute;
    left: 0;
    color: #222;
}

.pressed span:before {
    z-index: 1;
    background: -webkit-linear-gradient(transparent, transparent);
    background: -o-linear-gradient(transparent, transparent);
-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pressed span:after {
    text-shadow: 0 1px 2px rgba(255,255,255,0.2);
    z-index: -1;
}