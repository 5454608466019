#doublechevron path:first-child {
    transform: translate(2px);
}
#doublechevron {
    transform: scale(1.2);
    transition: transform .25s;
    fill: #FFFFFF
}

div[id^="NthDrawerProduct"] {
    height: 25px;
}

#nthDrawerLogo {
    width: 100%;
}