/* Place custom CSS styling for components widget at runtime in this file */
#RejectReasonswrapper #rejectsScrollButtons span.fa {
    font-size: .2rem;
    overflow: visible;
    line-height: initial;
}

#dteScrollButtons > div span.fa {
    font-size: .2rem;
    overflow: visible;
    line-height: initial;
}

.widget-pie {
    overflow: hidden;
}

.clickable {
    cursor: pointer;
}

body {
    overflow-y: auto;
    overflow-x: hidden !important;
    touch-action: pan-y;
}

.timePicker .scrollButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
}

.timePicker .arrowButton .material-icons .MuiIcon-root .fa {
    padding: 0 !important;

}

.timePicker .hour {
    scroll-snap-align: center;
    height: 33.3%;
    z-index: 2;
    position: relative;
    width: 100%;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    margin-top: 4%;
}

.timePicker .hour:first-child {
    scroll-snap-align: center;
    color: #22222266;
    font-weight: 400;
    cursor: pointer;
}

.timePicker .hour:last-child {
    scroll-snap-align: center;
    color: #22222266;
    font-weight: 400;
    cursor: pointer;
}

/****************** START PICKER ICON STYLE  ******************/
input[type="date"]::-webkit-calendar-picker-indicator {
    margin-left: 5px;
    padding: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    margin-left: 5px;
    padding: 0;
}

.dark input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(.5) brightness(1.6);
}

.light input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(.5) brightness(.8);
}

.dark input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(.5) brightness(1.6);
}

.light input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(.5) brightness(.8);
}

/*noinspection CssInvalidPseudoSelector*/
::-webkit-datetime-edit {
    max-width: max-content;
}

/****************** END PICKER ICON STYLE  ******************/
/****************** END SIMPLE TIME PICKER ******************/

/* xs for Material */
@media screen and (max-width: 600px) {
    body {
        /*background-color: red !important;*/
        padding-left: 5px;
        padding-right: 5px;
    }

    #NavigationBar {
        padding: 0 !important
    }
}

/* sm for Material */
@media screen and (min-width: 600px) and (max-width: 960px) {
    body {
        /*background-color: orange !important;*/
        padding-left: 5px;
        padding-right: 5px;
    }

    #NavigationBar {
        padding: 0 !important
    }

}

/* md for Material */
@media screen and (min-width: 960px) and (max-width: 1280px) {
    body {
        /*background-color: yellow !important;*/
        padding-left: 5px;
        padding-right: 5px;
    }

    #NavigationBar {
        padding: 0 !important
    }
}

/* lg for Material */
@media screen and (min-width: 1280px) and (max-width: 1920px) {
    body {
        /*background-color: green !important;*/
    }

    #NavigationBar {
        max-width: 320px;
    }

}

/* xl for Material */
@media screen and (min-width: 1920px) {
    body {
        /*background-color: blue !important;*/
    }

}


/****************** CONFETTI ******************/

@keyframes confetti {
    0% {
        transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
    }
    25% {
        transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
    }
    50% {
        transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
    }
    75% {
        transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
    }
    100% {
        transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
    }
}

/****************** END CONFETTI ******************/

/****************** EXPORT BUTTON *****************/
.ExportButton {
    height: 100%;
    width: 100%;
    align-content: center;
    text-align: center;
    padding-right: 5%;
    padding-top: 2%;
}

.ExportButton span {
    line-height: initial;
}

/**************** END EXPORT BUTTON ***************/

/**************** NTH LOGO ***************/
#NTHLogo {
    position: fixed;
    bottom: 15px;
    left: 0;
    pointer-events: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    max-height: 10vh;
}

#NTHfixedLogo {
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 50;
    pointer-events: none;
}

/**************** END NTH LOGO ***************/